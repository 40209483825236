import React from 'react';
import Header from "./Header";

const NotFound = () => (
  <div className="login-wrapper">
    <Header />
    <div className="login">
      <h2>404 - Sivua ei löydy!</h2>
      <p><a href="https://app.steinheide.com/login">Kirjaudu palveluun</a></p>
    </div>
  </div>
);


export default NotFound;