import React from 'react';
import PropTypes from 'prop-types';
import Field from "./Field";
import { firebaseStorage, firebaseFunctions, StripePublishableKey } from "../base";



class Form extends React.Component {
  state = {
    preview: false,
    fields: {},
    consent: false,
    sent: false,
    error: false,
    imageURL: "",
    paymentProcessing: false
  };


  refImages = null;

  componentDidMount(){
    this.setState({
      fields: this.props.order.fields
    });
  }

  // clean up
  componentWillUnmount() {
    this.setState({
      fields: {}
    });
    this.refImages = null;
  }

  checkRequired = () => {
    const fields = this.state.fields;
    let error = false;
    for (var key of Object.keys(fields)){
      const field = fields[key];
      if (field && field.hasOwnProperty("value")){
        if (!field.value && field.required === true){
          field.error = true;
          field.errorMessage = "*Pakollinen kenttä";
          error = true;
        }
        else {
          field.error = false;
          field.errorMessage = "";
        }
      }
    }
    this.setState({
      fields: fields,
      error: true,
    })
    return error;
  }

  showPreview = (event) => {
    event.preventDefault();
    const error = this.checkRequired();
    if (!error){
      const orderId = this.props.orderId;
      this.setState({
        preview: true,
        error: false,
        paymentProcessing: false
      });
      this.props.updateOrder(orderId, {fields: this.state.fields, preview: true});
    }
  }

  returnToForm = (event) => {
    event.preventDefault();
    const orderId = this.props.orderId;
    this.setState({
      preview: false,
      consent: false,
      paymentProcessing: false
    });
    this.props.updateOrder(orderId, {fields: this.state.fields, preview: false});
  }

  updateField = (fieldId, newValue) => {
    if (newValue && newValue !== "" && fieldId !== ""){

    //set new value to field
    const fields = {...this.state.fields};

      if (fields.hasOwnProperty(fieldId)){
        fields[fieldId]['value'] = newValue;
        this.setState({
          fields: fields
        });
      }
    }
  }

  submitForm = async (event) => {
    event.preventDefault();
    if (!this.state.consent) {
      return;
    }
    this.setState({paymentProcessing: true});
    const orderId = this.props.orderId;
    let userData = await this.props.submitOrder(orderId, this.state.fields);
    if (!userData) {
      return false;
    }
    const createCheckoutSession = firebaseFunctions.httpsCallable('createCheckoutSession');
    createCheckoutSession({
      customer_email: userData.email,
      order_id: orderId,
      success_url: window.location.origin + "/success",
      cancel_url: window.location.origin + `/order/${userData.uid}/${orderId}`,
      product_name: "prod1"
    }).then((result) => {
      const data = result.data;
      var stripe = window.Stripe(StripePublishableKey);
      stripe.redirectToCheckout({
        sessionId: data.sessionId
      }).then(function (result) {
        alert("PAYMENT REDIRECT ERROR");
        console.log("PAYMENT REDIRECT ERROR", result.error.message);
        this.setState({paymentProcessing: false});
      });
    }).catch((error) => {
      alert("PAYMENT ERROR");
      console.log("PAYMENT ERROR", error);
      this.setState({paymentProcessing: false});
    });

    return false;
  }


  handleUploadSuccess = (filename, fieldId) => {
    this.refImages = firebaseStorage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {
         this.setState({ imageURL: url })
         this.updateField(fieldId, url);
       });
  };

  sortFields = (a, b) => {
    let anum = a.split("_")[1];
    let bnum = b.split("_")[1];
    return anum - bnum;
  }



  render(){
    const { order } = this.props;

    const preview = order.hasOwnProperty("preview") && order.preview ? true : false;
    const sent = order.hasOwnProperty("sent") && order.sent ? true : false;
    const consent = this.state.consent;
    const paymentProcessing = this.state.paymentProcessing;

    return (
        <div className="form-wrapper center">
        { preview ? (
          <div className="preview">
            { sent ? (
              <h1>Lähetetty aineisto</h1>
            ) : (
              <h1>Lähetä aineisto</h1>
            )}
            <form className="form">
              { sent ? (
                  <div>
                    <div className="preview back">
                      <button onClick={() => this.props.history.push(`/account/`)}>Palaa</button>
                    </div>
                    Aineisto odottaa auditointia
                  </div>
                ) : (
                  <div className="form-desc big-text">Auditointi kestää noin viikon. Saat sen valmistuttua sähköpostiisi tulokset.
                  </div>
                )}
                <div className="fields">
                  {!sent &&
                    <h2 className="center"> Tarkista valinnat </h2>
                  }
                  {order.hasOwnProperty('fields') &&
                    Object.keys(this.props.order.fields).sort(this.sortFields).map(key => (
                    (this.props.order.fields[key].hasOwnProperty('value')) && (
                      <Field
                        key={key}
                        index={key}
                        field={this.props.order.fields[key]}
                        updateField={this.updateField}
                        preview={true}
                        imageURL={this.state.imageURL}
                      />
                    )
                  ))}
                </div>
                { !sent &&
                  <>
                    <div className="preview consent">
                      <input type="checkbox" name="consent" id="consent" value="hyväksyn ehdot" onChange={(e) => this.setState({consent: e.target.checked})} required />
                      <label htmlFor="consent">olen lukenut ja hyväksyn <a href="#">ehdot</a></label>
                    </div>
                    <div className="preview back">
                      <button type="button" onClick={this.returnToForm}>Palaa</button>
                    </div>
                    <button className="form-continue" type="button" disabled={!consent || paymentProcessing} onClick={this.submitForm}>Maksa</button>
                  </>
                }
              </form>
            </div>
        ) : (
          <>
            <h1>Uusi toimeksianto</h1>
            <div className="form" >
              <div className="form-desc big-text">Aloita määrittelemällä auditoitava aineisto.</div>
              <div className="field-wrapper">
                {order.hasOwnProperty('fields') &&
                  Object.keys(this.props.order.fields).sort(this.sortFields).map(key => (
                    (this.props.order.fields[key].hasOwnProperty('value')) && (
                      <Field
                        key={key}
                        index={key}
                        field={this.props.order.fields[key]}
                        updateField={this.updateField}
                        handleUploadSuccess={this.handleUploadSuccess}
                        imageURL={this.state.imageURL}
                      />
                    )
                ))}
              </div>
              {this.state.error &&
                <div className="field-error all"> Ole hyvä ja tarkista lomakkeen merkityt kentät</div>
              }
              <div className="field-wrapper back">
                <button onClick={() => this.props.history.push(`/account/`)}>Keskeytä</button>
              </div>
              <button className="form-continue"  onClick={this.showPreview} >Jatka</button>
            </div>
          </>
        )
        }
        </div>
    );
  }
}

Form.propTypes = {
  order: PropTypes.shape({
    fields: PropTypes.object
  }).isRequired,
  orderId: PropTypes.string.isRequired,
  updateOrder: PropTypes.func.isRequired,
  submitOrder: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default Form;
