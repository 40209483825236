import React from 'react';
import PropTypes from 'prop-types';
import Header from "./Header";
import base, { firebaseApp } from '../base';
import orderForm from "../empty-order";
import Form from "./Form";


class App extends React.Component {
  state = {
    uid: null,
    orders: {},
    currentOrder: null,
  }

  refOrders = null;
  refEmails = null;

  componentDidMount() {
    //let uid = null;
    let uid = this.state.uid || localStorage.getItem("uid");

    firebaseApp.auth().onAuthStateChanged(user => {
      // user signed out in other window sync the state
      if(!user && uid) {
        uid = null;
        localStorage.removeItem("uid");
        this.props.history.push('/login');
      }
    });

    const currentOrder = this.props.match.params.orderId;

    if (uid){
      this.refOrders = base.syncState(`/accounts/${uid}/orders/`, {
          context: this,
          state: 'orders',
      });
      this.setState({
        uid: uid,
      });

      if (currentOrder){
        this.setState({
          currentOrder: currentOrder,
        });
      }
      else {
        this.setState({
          currentOrder: null,
        });
      }
    }

    if (uid){
      this.refEmails = base.syncState(`/emails/${uid}/`, {
          context: this,
          state: 'emails',
      });
    }

    if (!uid){
      this.props.history.push('/login');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if(this.state.uid !== prevState.uid){
      if (!this.state.uid){
        localStorage.removeItem("uid");
        this.cleanUp();
        this.props.history.push('/login');
      }
    }

    if(this.props.match.params.orderId !== prevProps.match.params.orderId){
      this.setState({
        currentOrder: this.props.match.params.orderId,
      });
      if(this.props.match.params.orderId !== prevProps.match.params.orderId){
        this.setState({
          currentOrder: this.props.match.params.orderId,
        });
      }
    }

  }

  // clean up
  componentWillUnmount() {
    this.cleanUp();
  }

  signOut = async () => {
    this.setState({
      uid: null,
      currentOrder: null,
    });
    this.cleanUp();
    firebaseApp.auth().signOut().then(() => {
      // Sign-out successful.
      localStorage.removeItem("uid");
      this.props.history.push('/login');
    }).catch(function(error) {
      console.log(error);
    });
  }

  cleanUp = () => {
    if (this.refOrders){
      base.removeBinding(this.refOrders);
    }
    if (this.refEmails){
      base.removeBinding(this.refEmails);
    }
  }


  newOrder = () => {
    const orders = {...this.state.orders};
    const timestamp = Date.now();
    const newOrder = `order_${timestamp}`;
    orders[newOrder] = orderForm;
    this.setState({
      orders: orders,
      currentOrder: newOrder
    });
    this.props.history.push(`/order/${this.state.uid}/${newOrder}`);
  }

  updateOrder = (orderId, orderUpdate) => {
    const orders = {...this.state.orders};
    orders[orderId] = orderUpdate;
    this.setState({
      orders: orders
    });
  }
  submitOrder = async (orderId, fields) => {
    const emails = {...this.state.emails};
    const user = await firebaseApp.auth().currentUser;
    const date = new Date().toString();
    if (user && user.email){
      fields.field_0 = {email: user.email, date_sent: date};
      if (!emails.hasOwnProperty(orderId)){
        emails[orderId] = {fields: fields};
        this.setState({
          emails: emails,
        })
      }
      return { email: user.email, uid: this.state.uid };
    }
  }

  formatOrderDate = ( orderId ) => {
    const parts = orderId.split('_');
    let date = null;

    if (parts.length > 1){
      date = new Date(parseInt(parts[1]));
      let day = date.getDate();
      let months = date.getMonth()+1;
      let year = date.getFullYear();
      date = (day + "." + months +"."  + year);
    }
    if (!date){
      date = orderId;
    }
    return date;
  }

  getOrderLabel = ( key, order ) => {
    const date = this.formatOrderDate( key );
    let type = "";
    let status = "";

    if (order.hasOwnProperty("fields") && order.fields.field_1 && order.fields.field_1.hasOwnProperty("value")){
      type = order.fields.field_1 !== "" ? order.fields.field_1.value : "";
      type = type ? type.charAt(0).toUpperCase() + type.slice(1) : "";
    }
    if (order.hasOwnProperty("sent") && order.sent){
      status = " - Lähetetty";
    }
    return "Toimeksianto: " + date + "  " + type + " " + status;
  }


  render(){
    const uid = this.state.uid;
    const currentOrder =  this.state.currentOrder;
    const orders = this.state.orders;
    const success = this.props.match.path === "/success";

    return (
      <>
      <div className="App">
        <Header signOut={this.signOut}/>
        { currentOrder ? (
          <div className="order-list center">
            {(orders && orders.hasOwnProperty(currentOrder)) &&
              <Form
                order={this.state.orders[currentOrder]}
                orderId={currentOrder}
                updateOrder={this.updateOrder}
                submitOrder={this.submitOrder}
                history={this.props.history}
              />
            }
          </div>
          ) : (
          <div className="order-list center">
            { success ? (
              <div className="order-success">
                <h1>Hurraa!</h1>
                <h2 className="bigger"> Aineistosi on lähetetty auditoitavaksi </h2>
                <div className="big-text">
                  <p>Käsittelemme aineistosi mahdollisimman pian ja saat tulokset antamaasi sähköpostiosoitteeseen.</p>
                  <p>Olemme pian yhteydessä sinuun!</p>
                </div>
                <button className="order-button" onClick={this.newOrder}>
                  Tee uusi toimeksianto
                </button>
                <div>
                  <a href={`/account/`}>Palaa etusivulle</a>
                </div>
              </div>
            ) : (
              <>
              <h1>Toimeksiannot</h1>
                <button className="order-button" onClick={this.newOrder}>
                  Tee uusi toimeksianto
                </button>
                <div className="big-text">Aiemmat toimeksiannot</div>
                <div className="order-list-wrapper">
                  {Object.keys(orders).map(key => (
                    <div key={key} className="order-list-item"><a href={`/order/${uid}/${key}`} >{this.getOrderLabel(key, orders[key])} </a></div>
                  ))}
                </div>
              </>
            )}
          </div>
          )
        }
        <div className="push"></div>
      </div>
      <div className="push"></div>
      </>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}


export default App;
