import Rebase from 're-base';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAzLKbdXlyFGV-QbvaY9AneBru50-dJcno",
  authDomain: "dev-steinheide-app.firebaseapp.com",
  databaseURL: "https://dev-steinheide-app-default-rtdb.firebaseio.com/",
  projectId: "dev-steinheide-app",
  storageBucket: "dev-steinheide-app.appspot.com",
  messagingSenderId: "975874551432",
  appId: "1:975874551432:web:b173051ba76773a354393d"
});

const base = Rebase.createClass(firebaseApp.database());

const firebaseStorage = firebaseApp.storage();
const firebaseFunctions = firebaseApp.functions();

const StripePublishableKey = "pk_test_51JiFtzKAQecV81D6H2oioz4yA56RZgUw0bgNfCHKDHcMxTjuvt8LvGLOTzQ7dWiP5VmboEcJdQ8Y4rdePx59nIr8008LTuJbms";

// this is a named export
export { firebaseApp, firebaseStorage, firebaseFunctions, StripePublishableKey };

export default base;