import React from 'react';
import PropTypes from 'prop-types';
import Header from "./Header";
import { firebaseApp } from '../base';

class Login extends React.Component {
  state = {
    email: null,
    uid: null,
    error: null,
    newUser: false,
  }
  emailRef = React.createRef();

  componentDidMount() {
    // get info from state or local storage
    let uid = this.state.uid || localStorage.getItem("uid");

    let email = localStorage.getItem("emailProvided");

    if (!uid){
        //sign in params in url
        if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Vahvista sähköpostiosoitteesi, kiitos.');
          }
          if (email){
            // The client SDK will parse the code from the link for you.
            firebaseApp
              .auth()
              .signInWithEmailLink(email, window.location.href)
              .then((result) => {
                if (result){
                  const newUser = result.additionalUserInfo.isNewUser;
                  //clean up
                  localStorage.removeItem("emailProvided");
                  //sign the user in
                  this.signInUser(result.user.uid, newUser);
                }
              })
              .catch((error) => {
                this.setState({
                  error: error.code
                });
                console.log(error);
              });
          }
        }
    }
  }

  authenticate = (event) => {
    event.preventDefault();
    var email = this.emailRef.current.value;
    if (email){
      this.authenticateEmail(email);
    }
  }

  setEmail = (email) => {
    this.setState({
      email: email
    });
    localStorage.setItem('emailProvided', email);
  }

  authenticateEmail = async (email) => {

      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `https://dev.steinheide.com/login`,
        // This must be true.
        handleCodeInApp: true,
      };
      firebaseApp
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(this.setEmail(email))
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        console.log(error.code);
        if (error.code === "auth/user-disabled"){
          this.setState({
            newUser: true,
            uid: null,
          });
          localStorage.removeItem("emailProvided");
        }
        this.setState({
          error: error.code
        });
      });

  }


  signInUser = (uid, newUser) => {
    if (newUser){
      uid = null;
      this.setState({
        newUser: true,
        uid: null
      });
      localStorage.removeItem("uid");
    }
    else if (uid){
      this.setState({
        uid: uid
      });
      localStorage.setItem('uid', uid);
      this.props.history.push(`/account/`);
    }
  }

  clearEmail = (event) => {
    event.preventDefault();
    localStorage.removeItem("emailProvided");
    this.setState({
      email: null,
      error: null,
    });
    this.props.history.push("/login");
  }

  render(){
    const email = this.state.email || localStorage.getItem("emailProvided");
    const {error, newUser} = this.state;
    let disabledUser = (error === "auth/user-disabled") ? true: false;

    const CheckEmail = (props) => (
      <div className ="login login-sent">
        <h1>Tarkista sähköpostisi</h1>
        <div className="login-text big-text">
          Sähköpostiisi on lähetetty kirjautumislinkki.
        </div>
        <div>
          Etkö saanutkaan linkkiä? <br />
          <a href="/login" onClick={props.clearEmail}> Palaa edelliseen näkymään ja yritä uudelleen </a>.
        </div>
      </div>
    );

    const NewUser = (props) => (
      <div className="login login-disabled">
	    <h1>Tervetuloa palveluun!</h1>
        <div className="login-text big-text">
        <p>Tilisi on luotu. Ole hyvä ja kirjaudu vielä kerran sähköpostilinkin kautta ja olet palvelussa.</p>
        </div>
        <a href="/login">  Kirjaudu sisään tästä. </a>
      </div>
    );

    const ErrorScreen = (props) => (
      <div className="login login-error">
        <h1>Kirjautumisessa tapahtui virhe:</h1>
        <p>Klikkaamasi linkki on jo luultavasti käytetty tai selaimesi oli evästeitä hylkäävässä tilassa.</p>
        <p>({props.error})</p>
        <a href="/login" onClick={props.clearEmail}> Palaa edelliseen näkymään ja yritä uudelleen </a>.
      </div>
    );

    const LoginForm = (props) => (
      <nav className="login">
        <h1>Kirjaudu palveluun</h1>
        <div className="login-text big-text">Voit kirjautua tai rekisteröityä palveluun syöttämällä sähköpostiosoitteesi.</div>
        <input ref={props.emailRef} placeholder="Sähköpostiosoite" className="login-email" type="email" id="email" name="email" />
        <button className="login-button" onClick={props.authenticate}>Kirjaudu</button>
      </nav>
    );

    return (
      <div className="login-wrapper">
        <Header />
        { error
          ? (
              disabledUser
              ? ( <NewUser /> )
              : ( <ErrorScreen clearEmail={this.clearEmail} error={error}/> )
            )
          : (
              newUser
              ? ( <NewUser /> )
              : (
                  email
                  ? ( <CheckEmail clearEmail={this.clearEmail}/> )
                  : ( <LoginForm emailRef={this.emailRef} authenticate={this.authenticate}/> )
                )
            )
        }
      </div>
    )
  }

}

Login.propTypes = {
  history: PropTypes.object.isRequired
}

export default Login;