import React from 'react';
import PropTypes from 'prop-types';

class Header extends React.Component {
  render() {
    return (
      <header className="top">
        <img alt="Steinheide logo" src="/images/steinheide-logo.svg" />
        {
          this.props.signOut && (
            <div className="back">
              <button  onClick={this.props.signOut}>Sign out</button>
            </div>
          )
        }
      </header>
    );
  }
}

Header.propTypes = {
  signOut: PropTypes.func
};

export default Header;