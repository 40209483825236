// Empty form data
const form = {
  fields: {
  field_0: {
    email: ""
  },
  field_1: {
    id: 1,
    title: "Mainoksen tyyppi",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "online", value: "online"},
      2: {isSelected: false, text: "printti", value: "printti"},
      3: {isSelected: false, text: "ulkomainos", value: "ulkomainos"},
      4: {isSelected: false, text: "flyer tms", value: "flyer tms"},
      5: {isSelected: false, text: "esite", value: "esite"}
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_2: {
    id: 2,
    title: "Ensisijainen tavoite",
    type: "select",
    choices: {
      1: {isSelected: false, text: "Erottautuminen muiden mainosten joukosta", value: "Erottautuminen muiden mainosten joukosta"},
      2: {isSelected: false, text: "Tuotteen/brändin muistaminen", value: "Tuotteen/brändin muistaminen"},
      3: {isSelected: false, text: "Mielikuvan parantaminen", value: "Mielikuvan parantaminen"},
      4: {isSelected: false, text: "Aktivoiminen", value: "Aktivoiminen"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "Valitse ensisijainen tavoite",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_3: {
    id: 3,
    title: "Tarkenna brändimielikuva valitsemalla vaihtoehdoista",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Auktoriteetti / asiantuntija", value: "Auktoriteetti / asiantuntija"},
      2: {isSelected: false, text: "Mahdollistaja / avustaja", value: "Mahdollistaja / avustaja"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_4: {
    id: 4,
    title: "",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Perinteinen ja tavallinen", value: "Perinteinen ja tavallinen"},
      2: {isSelected: false, text: "Moderni ja innostava", value: "Moderni ja innostava"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_5: {
    id: 5,
    title: "",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Edullinen", value: "Edullinen"},
      2: {isSelected: false, text: "Laadukas", value: "Laadukas"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_6: {
    id: 6,
    title: "Määritä ensisijainen kohderyhmä",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Ammattilaiset", value: "Ammattilaiset"},
      2: {isSelected: false, text: "Amatöörit ja aloittelijat", value: "Amatöörit ja aloittelijat"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_7: {
    id: 7,
    title: "",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Oikeakätiset", value: "Oikeakätiset"},
      2: {isSelected: false, text: "Molemmat", value: "Molemmat"},
      3: {isSelected: false, text: "Vasenkätiset", value: "Vasenkätiset"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_8: {
    id: 8,
    title: "",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Naiset", value: "Naiset"},
      2: {isSelected: false, text: "Molemmat", value: "Molemmat"},
      3: {isSelected: false, text: "Miehet", value: "Miehet"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_9: {
    id: 9,
    title: "",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Uudet asiakkaat", value: "Uudet asiakkaat"},
      2: {isSelected: false, text: "Vanhat asiakkaat", value: "Vanhat asiakkaat"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_10: {
    id: 10,
    title: "Kilpailutilanne markkinoilla",
    type: "radio",
    choices: {
      1: {isSelected: false, text: "Useita toimijoita", value: "Useita toimijoita"},
      2: {isSelected: false, text: "Muutama suuri toimija", value: "Muutama suuri toimija"},
    },
    required: true,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_11: {
    id: 11,
    title: "Lisää auditoitava kuva",
    type: "file",
    choices: {},
    required: true,
    value: "",
    defaultValue: "",
    description: "Tuetut muodot: .jpg, .pdf, .png",
    error: false,
    errorMessage: "",
    placeholder: "Lisää kuva",
    cssClass: "",
    visibility: "visible",
    condition: {
      type: "hide",
      rules: {
        0: {
          fieldId: "1",
          value: "online"
        }
      }
    }
  },
  field_12: {
    id: 12,
    title: "",
    type: "textarea",
    choices: {},
    required: false,
    value: "",
    defaultValue: "",
    description: "",
    error: false,
    errorMessage: "",
    placeholder: "Lisätiedot",
    cssClass: "",
    visibility: "visible",
    condition: {}
  },
}
};

export default form;
